/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// global.scss
@import '~swiper/scss';
@import '~swiper/scss/autoplay';
@import '~swiper/scss/keyboard';
@import '~swiper/scss/pagination';
@import '~swiper/scss/scrollbar';
@import '~swiper/scss/zoom';
@import '~@ionic/angular/css/ionic-swiper';

@font-face {
  font-family: 'Helvetica';
  src: url('assets/fonts/Helvetica-Neue.ttf');
}
*{
  font-family: "Helvetica" !important;
}

.material-icons {
  font-family: 'Material Icons' !important;
}
.matInputField {
    width: 100%;
}

img.contactImage {
    height: 500px;
    width: 100%;
    object-fit: cover;
}
a{
    cursor: pointer;
}
.mat-select-value {
  padding-bottom: 5px !important;
}




// loading

@keyframes placeHolderShimmer {
    0% {
      background-position: -468px 0;
    }
  
    100% {
      background-position: 468px 0;
    }
  }
  
  .animated-background {
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: placeHolderShimmer;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 104px;
    height: 350px;
    position: relative;
  }
  
  .background-masker {
    background: #fff;
    position: absolute;
  }
  
  
.headingMargin {
  margin: 10px !important;
}
/* Every thing below this is just positioning */
  
  .background-masker.header-top,
  .background-masker.header-bottom,
  .background-masker.subheader-bottom {
    top: 200px;
    left: 40px;
    right: 0;
    height: 10px;
  }
  
  .background-masker.header-left,
  .background-masker.subheader-left,
  .background-masker.subheader-right {
    top: 210px;
    left: 40px;
    height: 8px;
    width: 10px;
  }
  
  .background-masker.header-bottom {
    top: 218px;
    height: 6px;
    left: 0;
  }
  
  .background-masker.subheader-left,
  .background-masker.subheader-right {
    top: 224px;
    height: 6px;
  }
  
  
  .background-masker.subheader-right {
    width: auto;
    left: 300px;
    right: 0;
  }
  
  .background-masker.subheader-right {
    left: 230px;
  }
  
  .background-masker.subheader-bottom {
    top: 296px;
    height: 10px;
    left: 0;
  }
  
  .background-masker.content-top,
  .background-masker.content-second-line,
  .background-masker.content-third-line,
  .background-masker.content-second-end,
  .background-masker.content-third-end,
  .background-masker.content-first-end {
    top: 240px;
    left: 0;
    right: 0;
    height: 6px;
  }
  
  .background-masker.content-top {
    height: 10px;
    top: 245px;
  }
  
  .background-masker.content-first-end,
  .background-masker.content-second-end,
  .background-masker.content-third-end {
    width: auto;
    left: 380px;
    right: 0;
    top: 260px;
    height: 8px;
  }
  
  .background-masker.content-second-line {
    top: 268px;
  }
  
  .background-masker.content-second-end {
    left: 420px;
    top: 274px;
  }
  
  .background-masker.content-third-line {
    top: 282px;
  }
  
  .background-masker.content-third-end {
    left: 300px;
    top: 288px;
  }
  
  .background-masker.header-top {
    top: 315px;
    left: 0;
    height: 7px;
  }
  
  .background-masker.header-right {
    top: 331px;
    width: 90%;
    left: 0;
    height: 6px;
  }
  
  .background-masker.header-left {
    right: 0;
    top: 314px;
    width: 50px;
    left: auto;
    height: 36px;
  }
  
  .background-masker.subheader-left {
    background: white;
    width: 62%;
    right: 0px;
    height: 22px;
    left: 121px;
  }
  
  // loading end


  .text-right{
    text-align: right;
  }

// spinner
//variables
$bg-color: #fff; //container background-color;
$basic-dark-color: #212121; //color of the spinner
$border-width: 4px; //width of spinners border
$basic-spinner-dimensions: 125px; //width and height of spinner
$main-spinner-dimensions: $basic-spinner-dimensions - $border-width * 2; //width and height of bigger circle
$small-spinner-dimensions: $main-spinner-dimensions * 0.7; //width and height of smaller circle

/* spinner style */
.spinner {
    position: relative;
    width: $basic-spinner-dimensions;
    height: $basic-spinner-dimensions;

    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      border-width: 4px;
      border-style: solid;
      border-radius: 50%;
    }
  }

/* spinner-1 styles */
.spinner.spinner-1 {
  
    @keyframes rotate-animation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes anti-rotate-animation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(-360deg);
    }
  }
  
  &:before {
    width: $main-spinner-dimensions;
    height: $main-spinner-dimensions;
    border-bottom-color: $basic-dark-color;
    border-right-color: $basic-dark-color;
    border-top-color: rgba($basic-dark-color, 0);
    border-left-color: rgba($basic-dark-color, 0);
    top: 0px;
    left: 0px;
    animation: rotate-animation 1s linear 0s infinite;
  }
  
  &:after {
    width: $small-spinner-dimensions;
    height: $small-spinner-dimensions;
    border-bottom-color: $basic-dark-color;
    border-right-color: $basic-dark-color;
    border-top-color: rgba($basic-dark-color, 0);
    border-left-color: rgba($basic-dark-color, 0);
    top: calc($main-spinner-dimensions - $small-spinner-dimensions) / 2;
    left: calc($main-spinner-dimensions - $small-spinner-dimensions) / 2;
    animation: anti-rotate-animation 0.85s linear 0s infinite;
  }
}


// custom

a{
  text-decoration: none;
}
.spinner-block {
    width: 100%;
    position: absolute;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    background: #ffffffba;
    height: 100%;
    z-index: 11;
    top: 0;
}
  // spinner end

  .text-center{
    text-align: center;
}
.mb-0{
  margin-bottom: 0px !important;
}
span.visually-hidden.ng-star-inserted{
  display: none !important;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #5c42ff;
}
h2, h1{
  color: #5c42ff;
}

hr {
  background: #5d41ff;
  margin-bottom: 15px;
  margin-top: 0;
}
.bookingTitleName {
  font-weight: bold;
  color: #5c42ff;
  margin-bottom: 10px;
  font-style: italic;
}

.timeline-wrapper {
  margin-bottom: 2rem;
  max-width: 95%;
}

.greenColor {
  color: #2e7d32;
}

b.refId {
  font-size: 13px;
  font-style: italic;
  font-weight: 100;
}

.paymentTitleText {
  margin-bottom: 7px;
  font-size: 12px;
}

.paymentTitleText b {
  color: black;
}
img.imgCourtImage {
    height: 200px;
    width: 100%;
    object-fit: cover;
}
@media screen and (max-width:600px) {

  h2.headTitle.heightMove.purpleTitle {
    font-size: 15px;
    margin-top: 0;
  }
  h1.noMatchText {
      font-size: 12px;
      letter-spacing: 3px;
  }

  h1.headingMargin.purpleTitle {
    font-size: 15px;
  }

  .headingMargin {
    font-size: 15px !important;
}
  ion-card {
    margin-bottom: 0;
  }

  img.contactImage {
    height: 170px;
}
  ion-card-title {
    font-size: 15px;
    margin-bottom: 10px;
  }
}